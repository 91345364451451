




















import Vue from 'vue'
import LeftBox from './LeftBox.vue'
import RightBox from './RightBox.vue'

export default Vue.extend({
  components: {
    LeftBox,
    RightBox,
  },
  props: {
    data: Object,
    init: Function,
    loading: Boolean,
    loadingRelativeBlockHeight: Boolean,
  },
})
