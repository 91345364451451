













































































































import Vue from "vue";
import { Table, TableColumn } from "element-ui";
export default Vue.extend({
  components: {
    "el-table": Table,
    TableColumn,
  },
  props: {
    loading: Boolean,
    list: Array,
  },
  computed: {
    foundation() {
      return process.env.VUE_APP_FOUNDATION;
    },
  },
  methods: {
    cellStyle({ row }: any) {
      return {
        backgroundColor: row.type === "pack" ? "rgba(255, 164, 87, 0.06)" : "",
      };
    },
  },
});
