









































































// eslint-disable-next-line @typescript-eslint/no-var-requires
const leftBoxbgPng = require('@/assets/images/blockChainBrowser/blockDetail/leftBoxbg.png')
import Vue from 'vue'
import CopyBtn from '../../CopyBtn.vue'
export default Vue.extend({
  components: { CopyBtn },
  props: {
    data: Object,
    loading: Boolean,
  },
  data() {
    return {
      leftBoxbgPng,
    }
  },
})
