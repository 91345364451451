











































































import ChainSearch from "@/components/pc/BlockChainBrowser/ChainSearch.vue";
import BlockOverview from "@/components/pc/BlockChainBrowser/BlockOverview/index.vue";
import TabList from "@/components/pc/BlockChainBrowser/TabList/index.vue";
import DataFilter from "@/components/pc/BlockChainBrowser/DataFilter/index.vue";
import TradeTable from "@/components/pc/BlockChainBrowser/TradeTable/index.vue";
import PageContainer from "@/components/pc/BlockChainBrowser/PageContainer.vue";
import blockDetailMixin from "@/mixin/blockDetail";
import VueTypedMixins from "vue-typed-mixins";
import ConsensusNodeTable from "./components/consensusNodeTable.vue";

export default VueTypedMixins(blockDetailMixin).extend({
  components: {
    ChainSearch,
    BlockOverview,
    PageContainer,
    TabList,
    DataFilter,
    TradeTable,
    ConsensusNodeTable,
  },
});
