





























import Vue, { PropType } from 'vue'
import lastBg from '@/assets/images/blockChainBrowser/blockDetail/lastBlockBg.png'
import nextBg from '@/assets/images/blockChainBrowser/blockDetail/nextBlockBg.png'

export default Vue.extend({
  props: {
    type: String as PropType<'last' | 'next'>,
    height: [Number, String],
    loading: Boolean,
  },
  data() {
    return {
      lastBg,
      nextBg,
    }
  },
  computed: {
    to(): any {
      return {
        path: '/blockDetail',
        query: {
          height: this.height,
        },
      }
    },
    url(): string {
      if (this.type === 'last') {
        return lastBg
      } else {
        return nextBg
      }
    },
    backgroundPosition(): string {
      if (this.type === 'last') return 'left center'
      else return 'right center'
    },
  },
})
